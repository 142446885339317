import Vue from 'vue'
import i18n from "./plugins/i18n"
import { buildVuetify } from "../_common/plugins/vuetify"
import '@mdi/font/css/materialdesignicons.css'
import Nav from "./components/Nav"
import Base from "./Base"
import * as VuetifyComponents from "vuetify/lib"

const vuetify = buildVuetify()
Vue.component('nav-bar', Nav)

new Vue({
    el: "#common-container",
    i18n,
    vuetify,
    components: {
        ...VuetifyComponents,
        'mpy-application': Base
    }
})
